<template>
    <v-container>
        <v-row class="mt-5">
            <v-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3">
                <login-card @success="onLoginSuccess"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import LoginCard from "@/components/entrance/LoginCard";
import {ROLES} from "@/utils/Constants";
import {mapGetters} from "vuex";

export default {
    components: {
        LoginCard
    },
    computed: {
        ...mapGetters('auth',
            ['clientCompletedQuiz', 'hasSignature']
        ),
    },
    methods: {
        onLoginSuccess() {
            const vm = this
            // console.log('login success', vm.currentUser)
            if (vm.isClient && !vm.clientCompletedQuiz) return vm.goTo({name: 'client.questionnaire'})
            if (vm.isDoctor && !vm.hasSignature) return vm.goTo({name: 'doctor.signature'})
            return vm.goHome()
        },
    },
}
</script>

<style scoped>

</style>
