<template>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
        <v-card tile>
            <v-toolbar color="primary" dark height="70px">
                <v-row align="center">
                    <v-col cols="2">
                        <v-img :src="imgIcon" contain max-width="40"/>
                    </v-col>
                    <v-col cols="8" class="text-truncate text-center">
                        Restablecer contraseña
                    </v-col>
                    <v-col cols="2" align="right">
                        <v-btn icon @click="hide">
                            <v-icon v-text="'mdi-close'"/>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-card-text>
                <v-row class="mt-3">
                    <v-col cols="12">
                        <v-form v-model="valid">
                            <kimsa-text-field
                                label="Email"
                                :rules="emailRules"
                                @change="email = $event"
                                :value="email"
                                outlined dense
                                inputMode="email"
                            />
                        </v-form>
                        <v-slide-y-transition>
                            <v-alert
                                v-show="error" dark color="error"
                                icon="mdi-alert-circle-outline"
                                dense class="round mt-3" border="left"
                            >
                                {{ error }}
                            </v-alert>
                        </v-slide-y-transition>
                    </v-col>
                    <v-col cols="12" class="text-center pb-0">
                        <v-btn class="normal-btn" color="primary" depressed x-large :loading="loading"
                               @click="resetPassword" :disabled="!valid">
                            Confirmar
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-btn class="normal-btn" color="secondary" depressed x-large @click="hide">
                            Cancelar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import {mapMutations, mapState} from 'vuex'
import {rules} from "@/utils/Rules";
import httpService from "@/services/httpService";

export default {
    components: {KimsaTextField},
    data() {
        return {
            dialog: false,
            loading: false,
            email: '',
            emailRules: [
                rules.required,
                rules.email,
            ],
            valid: false,
            error: '',
        }
    },
    computed: {
        ...mapState(['dialogActive']),
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show() {
            const vm = this
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            vm.reset()
        },
        reset() {
            const vm = this
            vm.loading = false
            vm.email = ''
        },
        async resetPassword() {
            const vm = this
            vm.error = ''
            vm.loading = true
            let response = await httpService.post('auth/reset-password', {email: vm.email}).catch(err => {
                vm.error = err.response.data.message
            })
            // console.log('response',response)
            vm.loading = false
            if(response) {
                vm.toast(response.data.message)
                vm.hide()
            }
        }
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

</style>
