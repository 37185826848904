<template>
    <v-form v-model="valid">
        <v-row>
            <v-col cols="12" class="mt-15">
                <v-img class="mx-auto" width="220" :src="imgLogo"/>
            </v-col>
            <v-col cols="10" offset="1" md="8" offset-md="2">
                <kimsa-text-field
                    label="e-mail"
                    placeholder="e-mail"
                    :rules="emailRules"
                    @change="email = $event"
                    :value="email"
                    outlined dense solo flat
                    inputMode="email"
                />
                <kimsa-password-field
                    label="Contraseña"
                    placeholder="Contraseña"
                    :rules="passwordRules"
                    @change="password = $event"
                    outlined dense solo flat
                    @enter="onLogin"
                    class="mt-5"
                />

                <div class="text-center mt-5">
                    <v-btn color="accent" large depressed class="normal-btn"
                           :loading="loading" :disabled="!valid" dark @click="onLogin">
                        Ingresar
                    </v-btn>
                    <br>
                    <v-btn color="white" small depressed class="normal-btn text-underline mt-3" dark text
                           @click="showResetPassword">
                        Olvidé mi contraseña
                    </v-btn>
                    <reset-password-dialog ref="reset"/>
                    <br>
                    <v-btn color="ternary" large depressed class="normal-btn mt-10" @click="goToRegister">
                        Crear cuenta
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>

import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import KimsaPasswordField from "@/components/common/inputs/KimsaPasswordField";
import {rules} from "@/utils/Rules";
import {mapActions} from "vuex";
import ResetPasswordDialog from "@/components/entrance/ResetPasswordDialog";

export default {
    name: "LoginCard",
    components: {
        ResetPasswordDialog,
        KimsaTextField,
        KimsaPasswordField,
    },
    data() {
        return {
            valid: false,
            email: '',
            password: '',
            showPassword: true,
            loading: false,
            emailRules: [
                rules.required,
                rules.email,
            ],
        }
    },
    computed: {
        passwordRules() {
            return [rules.required, rules.minLength(this.password, 6)]
        },
    },
    mounted() {
        const vm = this
        vm.email = vm.$route?.query?.email || ''

    },
    methods: {
        ...mapActions({login: 'auth/login'}),
        async onLogin() {
            const vm = this
            vm.loading = true
            let response = await vm.login({email: vm.email, password: vm.password})
            // console.log('response login',response)
            vm.loading = false
            if (response.data.error) return vm.toast(response.data.message, 'error')
            vm.$emit('success')
        },
        goToRegister() {
            const vm = this
            vm.goTo({name: 'doctor.register'})
        },
        showResetPassword() {
            const vm = this
            vm.$refs.reset.show()
        }
    },
}
</script>

<style scoped>

.subtitle-login {
    font-size: 14px;
    line-height: 16px;
    color: #939BB3;
    margin-bottom: 37px;
}

</style>
